<template>
    <section class="invoice-preview-wrapper">
        <b-row class="invoice-preview">
            <!-- Col: Left (Invoice Container) -->
            <b-col cols="12" md="9" id="to-print">
                <b-card no-body class="invoice-preview-card">
                    <!-- Header -->
                    <b-card-body class="invoice-padding pb-0">
                        <div class="
                  d-flex
                  justify-content-between
                  flex-md-row flex-column
                  invoice-spacing
                  mt-0
                ">
                            <!-- Header: Left Content -->
                            <div v-if="user_type === 'accounts'">
                                <div class="logo-wrapper">
                                    <b-img :src="logo" alt="logo" class="fp_logo mb-5" />
                                </div>
                                <p class="card-text mt-5 mb-25">From</p>
                                <h6 class="mb-25">Aliff Overseas LLC</h6>
                                <p class="card-text mb-25">8 The Green Ste A</p>
                                <p class="card-text mb-25">Dover De 19901</p>
                                <p class="card-text mb-25">Email id: accounts@aliff.in</p>
                                <p class="card-text mb-25">Phone: 8767839300</p>
                                
                            </div>
                            <div v-else>
                                <div class="logo-wrapper">
                                    <b-img :src="logo" alt="logo" class="fp_logo mb-5" />
                                </div>
                                <p class="card-text mt-5 mb-25">From</p>
                                <h6 class="mb-25">Franchise</h6>
                                <p class="card-text mb-25">Adresss</p>
                                <p class="card-text mb-25">Dover De 19901</p>
                                <p class="card-text mb-25">Email id: franchise@aliff.in</p>
                                <p class="card-text mb-25">Phone: 8767839300</p>
                                
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0">
                                <div class="invoice-date-wrapper d-flex" style="gap: 10px">
                                    <h4 class="invoice-title mb-2">
                                        Invoice
                                        <span class="invoice-number">#INV-001</span>
                                    </h4>
                                </div>
                                <div class="invoice-date-wrapper d-flex" style="gap: 10px">
                                    <p class="invoice-date-title">Invoice Date:</p>
                                    <p class="invoice-date">{{ moment(workingInvoice.invoice_raised_date).format('DD-MM-YYYY') }}</p>
                                </div>
                            </div>
                        </div>
                    </b-card-body>

                    <!-- Spacer -->
                    <hr class="invoice-spacing" />

                    <!-- Invoice Client & Payment Details -->
                    <!-- <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <b-col cols="12" xl="6" class="p-0">
                  <h6 class="mb-2">Shipping To:</h6>
                  <h6 class="mb-25">John Doe</h6>
                  <p class="card-text mb-25">123 Street, New York</p>
                  <p class="card-text mb-25">New York, USA, 12345</p>
                  <p class="card-text mb-25">123-456-7890</p>
                  <p class="card-text mb-0">john@example.com</p>
                </b-col>
  
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6 class="mb-2">Payment Details:</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">Total Due:</td>
                          <td>
                            <span class="font-weight-bold">&#x20B9;800</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">Bank name:</td>
                          <td>Sample Bank</td>
                        </tr>
                        <tr>
                          <td class="pr-1">Country:</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td class="pr-1">IBAN:</td>
                          <td>123456789</td>
                        </tr>
                        <tr>
                          <td class="pr-1">SWIFT code:</td>
                          <td>ABCDEFGH</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body> -->

                    <!-- Invoice Description: Table -->
                    <b-table-lite responsive :items="[workingInvoice]" :fields="fields">
                        <template #cell(actual_commission)="data">
                            <span v-if="data.item.commission.total_commission && user_type === 'accounts'">{{ Math.floor(data.item.commission.total_commission) }}</span> 
                            <span v-else>{{ data.item.commission.franchise_commission }}</span> 
                        </template>
                        <template #cell(crm_percent)="data">
                           <span v-if="data.item.commission.topup_percent && user_type === 'accounts'">{{ data.item.commission.topup_percent }}</span>
                           <span v-else>{{ data.item.crm_percent }}</span>
                        </template>
                        <!-- <template #cell(soi_quantity)="data">
                <b-card-text>
                  {{ data.item.soi_quantity }}
                </b-card-text>
              </template>
<template #cell(soi_amount)="data">
                <b-card-text> ${{ data.item.soi_amount }} </b-card-text>
              </template> -->
                    </b-table-lite>

                    <!-- Invoice Description: Total -->
                    <b-card-body class="invoice-padding pb-0">
                        <b-row>
                            <!-- Col: Sales Person -->
                            <b-col cols="12" md="6" class="mt-md-0 mt-3">
                                <table v-if="user_type === 'accounts'">
                                    <tbody>
                                        <tr>
                                            <td class="pr-1">Bank Name:</td>
                                            <td>{{ workingInvoice.bank_account.ba_name }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">Beneficiary Name:</td>
                                            <td>{{ workingInvoice.bank_account.ba_beneficiary_name }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">Address:</td>
                                            <td>{{ workingInvoice.bank_account.ba_address }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">Account Number:</td>
                                            <td>{{ workingInvoice.bank_account.ba_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">Routing Number:</td>
                                            <td>{{ workingInvoice.bank_account.ba_routing_number }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table v-else>
                                    <tbody>
                                        <tr>
                                            <td class="pr-1" v-if="workingInvoice.bank_account.ba_name">Bank Name:</td>
                                            <td>{{ workingInvoice.bank_account.ba_name }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">Beneficiary Name:</td>
                                            <td>{{ workingInvoice.bank_account.ba_beneficiary_name }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">Address:</td>
                                            <td>{{ workingInvoice.bank_account.ba_address }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">Account Number:</td>
                                            <td>{{ workingInvoice.bank_account.ba_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">Routing Number:</td>
                                            <td>{{ workingInvoice.bank_account.ba_routing_number }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="mt-4">
                                    <b-img height="40" width="100" v-if="workingInvoice.signature && workingInvoice.signature.sg_file_path"
                                        :src="FILESURL + workingInvoice.signature.sg_file_path" alt="signature" />
                                    <p class="mt-2" v-if="workingInvoice.signature.sg_name">{{ workingInvoice.signature.sg_name }}</p>
                                </div>
                            </b-col>
                            <!-- Col: Total -->
                            <b-col cols="12" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2" v-if="user_type === 'accounts'">
                                <div class="invoice-total-wrapper">
                                    <div class="invoice-total-item d-flex justify-content-between" style="gap: 20px"
                                        v-if="workingInvoice.commission && workingInvoice.commission.bonus_amount_commission">
                                        <p class="invoice-total-title">Bonus:</p>
                                        <p class="invoice-total-amount font-weight-bold">
                                            {{ workingInvoice.commission.bonus_amount_commission }} {{
                                            workingInvoice.currency }}
                                        </p>
                                    </div>
                                    <div class="invoice-total-item d-flex justify-content-between" style="gap: 20px"
                                        v-if="workingInvoice.commission && workingInvoice.commission.topup_percent">
                                        <p class="invoice-total-title">Topup on Market Commission:</p>
                                        <p class="invoice-total-amount font-weight-bold">
                                            {{ workingInvoice.commission.topup_percent }}%
                                        </p>
                                    </div>
                                    <hr class="my-1" />
                                    <div class="invoice-total-item d-flex justify-content-between" style="gap: 20px">
                                        <p class="invoice-total-title">Total:</p>
                                        <p class="invoice-total-amount font-weight-bold" v-if="workingInvoice.commission.total_commission">
                                            {{ Math.floor(workingInvoice.commission.total_commission) }} {{ workingInvoice.currency }}
                                        </p>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="12" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2" v-else>
                                <div class="invoice-total-wrapper">
                                   
                                 
                                    <hr class="my-1" />
                                    <div class="invoice-total-item d-flex justify-content-between" style="gap: 20px">
                                        <p class="invoice-total-title">Total (in INR):</p>
                                        <p class="invoice-total-amount font-weight-bold" v-if="workingInvoice.commission.franchise_inr_net">
                                            {{ workingInvoice.commission.franchise_inr_net }} INR
                                        </p>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!-- Spacer -->
                    <hr class="invoice-spacing" />

                    <!-- Note -->
                    <b-card-body class="invoice-padding pt-0 text-center">
                        <span>It was a pleasure working with you , Thank You.</span>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Right Col: Card -->
            <b-col cols="12" md="3" class="invoice-actions">
                <b-card>
                    <p v-if="workingInvoice.its_id === 4 && workingInvoice.commission.inr_commission && user_type === 'accounts'">
                       Expected Commision(When Raised) - {{ workingInvoice.commission.inr_commission }}
                    </p>
                    <v-select v-model="workingInvoice.its_id" class="mb-75" v-if="user_type === 'accounts'"
                        :options="invoiceStatuses" label="its_name" :reduce="(item) => item.its_id"
                        placeholder="Invoice Status" @input="updateInvoice(workingInvoice)" />

                    <b-form-input v-if="user_type === 'accounts' && workingInvoice.its_id > 3"
                        @input="updateInvoice(workingInvoice)" 
                        v-model="workingInvoice.uni_recieved_commission" 
                        type="number" 
                        placeholder="Commission Received from University" 
                        class="mb-75" 
                        label="Commission Received from University" />

                    <b-button v-else-if="user_type === 'franchise' && workingInvoice.its_id === 4" @click="updateFranchiseInvoice(workingInvoice)" v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="mb-75" block>
                        Approve
                    </b-button>
                    <b-button v-else-if="user_type === 'franchise' && workingInvoice.its_id === 5" disabled="true"  v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="mb-75" block>
                        Approved
                    </b-button>
                    <!-- Button: Download -->
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="mb-75" block>
                        Download
                    </b-button>

                    <!-- Button: Print -->
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-primary" class="mb-75" block
                        @click="printInvoice">
                        Print
                    </b-button>
                </b-card>

                <b-card v-if="user_type === 'franchise'">
                    <div class="bill-details">
                        <div class="d-flex justify-content-between">

    <!-- let calculations = {
        inr_commission: invoice.accounts_net_commission_inr ? invoice.accounts_net_commission_inr : inr_commission,
		franchise_commission: franchise_commission,
        franchise_inr_gross: franchise_inr_gross.toLocaleString('en-IN'),
        franchise_inr_net: franchise_inr_net.toLocaleString('en-IN'),
        deducted_gst : deducted_gst.toLocaleString('en-IN'),
        deducted_tds : deducted_tds.toLocaleString('en-IN')
    } -->
                            <b>Commission:</b>
                            <span>{{ workingInvoice.commission.calculations.inr_commission | 0 }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <b>GST (18%):</b>
                            <span>{{ workingInvoice.commission.calculations.deducted_gst | 0 }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <b>TDS (5%):</b>
                            <span>{{ workingInvoice.commission.calculations.deducted_tds | 0 }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <b>Bank Charges:</b>
                            <span>{{ workingInvoice.bank_charges | 0 }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <b>Net Commission:</b>
                            <span>{{  workingInvoice.commission.calculations.franchise_inr_net | 0 }}</span>
                        </div>
                        <!-- <hr /> -->
                        <!-- <div class="d-flex justify-content-between font-weight-bold">
                            <b>Total:</b>
                            <span>70000</span>
                        </div> -->
                    </div>
                </b-card>
            </b-col>


        </b-row>
    </section>
</template>

<script>
import {
    BCard,
    BAlert,
    BRow,
    BCol,
    BCardBody,
    BTableLite,
    BButton,
    BLink,
    VBToggle,
    BCardText,
    BImg,
    BFormInput
} from "bootstrap-vue";
import AliffLogo from "@/theme-variables/assets/Logo.png";
import CommonServices from "@/apiServices/CommonServices";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import { FILESURL } from "@/config";
import store from "@/store";
import AccountsServices from "@/apiServices/AccountsServices";
import vSelect from "vue-select";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import user from "@/store/user";
import AgentServices from "@/apiServices/AgentServices";


export default {
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },
    components: {
        BCard,
        BAlert,
        BRow,
        BCol,
        BCardBody,
        BCardText,
        BTableLite,
        BButton,
        BLink,
        BImg,
        vSelect,
        BFormInput
    },
    data() {
        return {
            order_details: {},
            workingInvoice: {},
            invoiceStatuses: [],
            status_id: null,
            fields: [
                { key: "sr_no", label: "Sr. No." },
                { key: "user_id", label: "Student ID" },
                { key: "user_firstname", label: "Student Name" },
                { key: "intake_month", label: "Program/Intake" },
                { key: "course_name", label: "Course" },
                { key: "university_name", label: "University Name" },
                { key: "course_fee_pa", label: "Tution Fee" },
                { key: "scholarship_amount", label: "Scholarship" },
                { key: "crm_percent", label: "Commission (%)" },
                { key: "actual_commission", label: "Amount" },
                { key: "currency", label: "Currency" },
            ],
            FILESURL,
            show_for_accounts:false,
            franchise_invoice:false,
            debounce:null
        };
    },
    computed: {
        user_type() {
            if (store.getters["user/getUserDetails"].user_type) {
                let user_type = store.getters["user/getUserDetails"].user_type;
                return user_type;
            }
            return null;
        },
        isPageVisible() {
            const isPaid = this.order_details.transaction_status;
            if (isPaid === "success") {
                return true;
            }
            return false;
        },
        logo() {
            const user_details = this.$store.getters["user/getUserDetails"];
            if (user_details.brand_logo) {
                return `${FILESURL}${user_details.brand_logo}`;
            }
            return AliffLogo;
        },
    },
    methods: {
        async getInvoiceStatuses() {
            try {
                const res = await AccountsServices.getInvoiceStatuses()
                if (res.data.status) {
                    this.invoiceStatuses = res.data.data
                }
            } catch (error) {
                console.log("Error getting invoice statuses", error)
            }
        },
        async getActiveInvoice(student_user_id) {
            try {
                let res = []
                if (this.user_type === 'accounts') {
                    res = await AccountsServices.getActiveInvoice({
                        student_user_id,
                    });
                }
                if (this.user_type === 'franchise') {
                    res = await AgentServices.getActiveInvoice({
                        student_user_id,
                    });
                }

                if (res.data.status) {
                    this.workingInvoice = res.data.data;
                    //   this.getBankAccounts()
                }
            } catch (error) {
                console.error("Error getting working invoice ", error);
            }
        },
        async updateFranchiseInvoice(invoice){
            this.getActiveInvoice(invoice.student_user_id);
            try {
                const res = await AgentServices.updateWorkingInvoice({
                    inv_id: invoice.inv_id,
                    its_id: 5,
                    // ba_id: invoice.ba_id,
                });
                if (res.data.status) {

                    this.getActiveInvoice(invoice.student_user_id);
                    if (this.workingInvoice.its_id == 5) {
                        this.$toast({
                            component: ToastificationContentVue,
                            props: {
                                title: "Invoice Aprroved",
                                icon: "Check",
                                variant: "success",
                            },
                        });
                    }
                }
            } catch (error) {
                console.error("Error updating invoice ", error);
            }
        },
        onChange(invoice){
            if (this.debounce) {
                this.debounce = null;
            }
            this.debounce = setTimeout(() => {
                this.updateInvoice(invoice);
            }, 1000);
        },
        async updateInvoice(invoice) {
            
            try {
                const res = await AccountsServices.updateWorkingInvoice({
                    inv_id: invoice.inv_id,
                    its_id: invoice.its_id,
                    ba_id: invoice.ba_id,
                    uni_recieved_commission : invoice.uni_recieved_commission
                });
                if (res.data.status) {

                    this.getActiveInvoice(invoice.student_user_id);
                    if (this.workingInvoice.its_id == 3) {
                        this.$toast({
                            component: ToastificationContentVue,
                            props: {
                                title: "Invoice Sent To University",
                                icon: "Check",
                                variant: "success",
                            },
                        });
                        this.$toast({
                            component: ToastificationContentVue,
                            props: {
                                title: "Commission Pending From University",
                                icon: "Check",
                                variant: "success",
                            },
                        });
                    }
                    if (this.workingInvoice.its_id == 4) {
                        this.$toast({
                            component: ToastificationContentVue,
                            props: {
                                title: "Commission Recieved",
                                icon: "Check",
                                variant: "success",
                            },
                        });

                    }


                }
            } catch (error) {
                console.error("Error updating invoice ", error);
            }
        },
        printInvoice() {
            document.querySelectorAll(".dont-print").forEach((el) => {
                el.classList.add("print-show");
                el.classList.remove("dont-print");
            });

            window.print();

            setTimeout(() => {
                document.querySelectorAll(".print-show").forEach((el) => {
                    el.classList.add("dont-print");
                    el.classList.remove("print-show");
                });
            }, 5000);
        },
        async getCommissionRates() {
            try {
                const res = await CommonServices.get
            } catch (error) {
                
            }
        },

        moment,
    },
    beforeMount() {
        const { student_user_id } = this.$route.params;
        if (!isNaN(student_user_id)) {
            this.getActiveInvoice(student_user_id);
            if (this.user_type === 'accounts') {
                this.getInvoiceStatuses();
            }
        } else {
            console.error("Order ID is not a number");
        }
        const { franchise } = this.$route.query;
        if(franchise){
            this.franchise_invoice = true
        }
    },
};
</script>

<style lang="scss" scoped>
.fp_logo {
    height: 50px;
    width: 50px;
    margin-bottom: 35px;
    // left: 41px;

    // bottom: 10px;
}

@import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style lang="scss" scoped>
@media print {
@page{
    size:potrait;
    width: 100%;
    
}
    // Global Styles
    body {
        background-color: transparent !important;
display: block !important;  
      
    }

    nav.header-navbar {
        display: none;
    }

    .main-menu {
        display: none;
    }

    .content-header {
        display: none !important;
    }

    .header-navbar-shadow {
        display: none !important;
    }

    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }

    footer.footer {
        display: none;
    }

    .card {
        background-color: transparent;
        box-shadow: none;
    }

    .customizer-toggle {
        display: none !important;
    }

    .invoice-preview-wrapper {
        display: block !important;
       

        body {
            display: block !important;
            
        }

        .invoice-actions {
            display: none !important;
        }
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {
        
        .row.invoice-preview {
            .col-md-8 {
                max-width: 100%;
                flex-grow: 1;
            }

            .invoice-preview-card {
                transform: rotate(270deg) !important;
                transform-origin: center center  !important;
                .card-body:nth-of-type(2) {
                    .row {
                        >.col-12 {
                            max-width: 50% !important;
                        }

                        .col-12:nth-child(2) {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-end;
                            margin-top: 0 !important;
                        }
                    }
                }
            }
        }

        // Action Right Col
        .invoice-actions {
            display: none;
        }
    }
}


</style>